import Vue from "vue";
import Router from "vue-router";
import login from "../views/login.vue";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    name: "login",
    component: login,
    
  },

  {
    path: "/homepage",
    meta: {
      title: "我的钱包",
    },
    component: () => import("@/views/homepage"),
    name: "homepage",
  },
  {
    path: "/walletDetail",
    meta: {
      title: "钱包明细",
    },
    component: () => import("@/views/walletDetail"),
    name: "walletDetail",
  },
  {
    path: "/walletDetailSingle",
    meta: {
      title: "钱包单条明细",
    },
    component: () => import("@/views/walletDetailSingle"),
    name: "walletDetailSingle",
  },
  {
    path: "/getGiftDetailSingle",
    meta: {
      title: "收礼单条明细",
    },
    component: () => import("@/views/getGiftDetailSingle"),
    name: "getGiftDetailSingle",
  },
  {
    path: "/payord",
    meta: {
      title: "充值",
    },
    component: () => import("@/views/pay"),
    name: "payord",
  },
  {
    path: "/payouter",
    meta: {
      title: "充值",
    },
    component: () => import("@/views/pay_outer"),
    name: "payouter",
  },
  {
    path: "/agreement",
    meta: {
      title: "用户充值协议",
    },
    component: () => import("@/views/agreement"),
    name: "agreement",
  },
  {
    path: "/withdrawal",
    meta: {
      title: "提现",
    },
    component: () => import("@/views/withdrawal"),
    name: "withdrawal",
  },
];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const createRouter = () =>
  new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  // console.log(to, from, 99999999);
  // if (localStorage.getItem('token')) {
  //   if (to.query.code !== '' && (to.name !== 'pay')) {
  //     next({
  //       path: '/pay',
  //       query: {
  //         code: to.query.code
  //       }
  //     })
  //   } else if (to.name === 'login') {
  //     next({ path: '/homepage' })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next({ name: 'login' })
  // }
  if (to.name === 'login') {
    const token = localStorage.getItem('token')
    if (token) {
      next({ path: '/homepage' })
    } else {
      next()
    }
  } else if (from.path === to.path && to.name === 'homepage') {
    next({ path: '/homepage' })
  } else { 
    next()
  }
})


export default router;
